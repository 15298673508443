import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Row } from 'react-bootstrap';
import {
    GetArbitrationPlayerPresenters, GetArbitrationPlayerRepresentatives, GetArbitrationTeamRepresentatives,
    GetArbitrators, GetPlayers, GetReportArbitration
} from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import { moveItemInArray } from '../Common/CommonFunctions';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import StatFilter, { addFilterAsParameter, convertFilterToNote, createFilter } from '../Common/StatFilter';
import TabulatorTable from '../Common/TabulatorTable';

function ArbitrationReport() {
    let curSeason = new Date().getFullYear();

    const [columns, setColumns] = useState(null);
    const [data, setData] = useState(null);
    const [excelNotes, setExcelNotes] = useState(null);
    const [filters, setFilters] = useState([]);
    const [endSeason, setEndSeason] = useState(curSeason);
    const [startSeason, setStartSeason] = useState(curSeason);
    const [reportType, setReportType] = useState('all');

    useEffect(() => {
        (async function Initialize() {
            let allPlayersFromAPI = await GetPlayers();
            let arbitratorsFromAPI = await GetArbitrators();
            let playerPresentersFromAPI = await GetArbitrationPlayerPresenters();
            let playerRepresentativesFromAPI = await GetArbitrationPlayerRepresentatives();
            let teamRepresentativesFromAPI = await GetArbitrationTeamRepresentatives();
            let colGroups = await columnManager.getAllGroupsBySection('ArbitrationReport');
            let arbFilters = [];

            colGroups.find(group => group.label === 'ArbitrationFilters').columns
                .forEach((column, filterIndex) => {
                    let filter = Object.assign({}, createFilter(filterIndex, column));

                    switch (column.id) {
                        case 395:
                            filter.people = allPlayersFromAPI;
                            break;
                        case 802:
                            filter.people = arbitratorsFromAPI;
                            break;
                        case 806:
                            filter.people = teamRepresentativesFromAPI;
                            break;
                        case 807:
                            filter.people = playerRepresentativesFromAPI;
                            break;
                        case 808:
                            filter.people = playerPresentersFromAPI;
                            break;
                        default:

                            break;
                    }

                    if (filter.formInputs.inputType === "PersonSelect") {
                        filter.clearAfterSelection = true;
                    }

                    arbFilters.push(filter);
                });
            let arbColumns = colGroups.find(group => group.label === 'ArbitrationReport').columns
                .map(col => {
                    switch (col.title) {
                        case 'YS':
                            col.title = 'YS in PY';
                            break;
                        case 'Raise':
                            col.title = 'Raise (Excl. 1st Time Elig.)';
                            break;
                        default:
                            break;
                    }
                    return col;
                });

            let playerPresenterColIndex = arbColumns.findIndex(col => col.field === 'PlayerPresenter');
            let playerRepColIndex = arbColumns.findIndex(col => col.field === 'PlayerRep');
            let teamRepColIndex = arbColumns.findIndex(col => col.field === 'TeamRep');

            let arbColumnGroupings = {
                all: arbColumns,
                arbitrator: [{
                        cssClass: 'leftAlign',
                        field: 'ArbitratorName',
                        headerSort: true,
                        headerSortStartingDir: 'asc',
                        resizable: false,
                        hozAlign: 'left',
                        title: 'Arbitrator',
                        width: 140
                    }].concat(arbColumns),
                season: arbColumns,
                playerPresenter: moveItemInArray(arbColumns, playerPresenterColIndex, 0),
                playerRep: moveItemInArray(arbColumns, playerRepColIndex, 0),
                teamRep: moveItemInArray(arbColumns, teamRepColIndex, 0),
            }
            setColumns(arbColumnGroupings);
            setFilters(arbFilters);
        })()
    }, []);

    async function getData() {
        setData({ loading: true, rows: null })
        let seasons = Array.from(
            new Array(endSeason - startSeason + 1),
            (season, seasonIndex) => seasonIndex + Number(startSeason)
        );
        let filterObj = filters.reduce(addFilterAsParameter, { seasons: seasons });
        let res = await GetReportArbitration(filterObj);
        updateNotes(filters);
        setData(Object.assign({ exceptionMsg: res.exceptionMsg, loading: false }, res.length > 0 ? groupData(res) : {} ));
    }

    function groupData(arbData) {
        let aggDataCols = ['ArbCaseResult', 'aWAR', 'ArbPlayerSubmission', 'ArbMidPoint', 'ArbTeamSubmission', 'ArbSpread', 'ArbFinalAmount', 'MoneyPeraWAR', 'YS', 'Raise']

        let totalGroupingDataAgg = {};
        arbData?.slice(0)
            .map(arbCase => {
                arbCase.totalGroup = 'totalGroup';
                return arbCase;
            })
            .forEach(arbCase => groupDataByCol('totalGroup', totalGroupingDataAgg, arbCase));

        aggDataCols.forEach(aggDataCol => {
            totalGroupingDataAgg.totalGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'Team Win', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'Settled (1yr)', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'Total(1yr)', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'Settled (multi-yr)', totalGroupingDataAgg.totalGroup)
                + formatAggData(aggDataCol, 'Total', totalGroupingDataAgg.totalGroup)
        });
        totalGroupingDataAgg.totalGroup['_children'] = arbData?.slice(0);

        let totalGroupingData = [totalGroupingDataAgg.totalGroup];

        let arbGroupingData = {};
        arbData.filter(arbCase => arbCase.Arbitrators?.length > 0)
            .flatMap(arbCase => arbCase.Arbitrators.map(arbitrator => Object.assign(arbitrator, arbCase)))
            .forEach(arbCase => groupDataByCol('ArbitratorName', arbGroupingData, arbCase));

        Object.values(arbGroupingData).forEach(arbGroup => {
            aggDataCols.forEach(aggDataCol => {
                arbGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', arbGroup)
                    + formatAggData(aggDataCol, 'Team Win', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total(1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (multi-yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total', arbGroup)
            })
        })

        let seasonGroupingData = {};
        arbData.forEach(arbCase => groupDataByCol('Season', seasonGroupingData, arbCase));

        Object.values(seasonGroupingData).forEach(arbGroup => {
            aggDataCols.forEach(aggDataCol => {
                arbGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', arbGroup)
                    + formatAggData(aggDataCol, 'Team Win', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total(1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (multi-yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total', arbGroup)
            })
        })

        let playerPresenterGroupingData = {};
        arbData.forEach(arbCase => groupDataByCol('PlayerPresenter', playerPresenterGroupingData, arbCase));

        Object.values(playerPresenterGroupingData).forEach(arbGroup => {
            aggDataCols.forEach(aggDataCol => {
                arbGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', arbGroup)
                    + formatAggData(aggDataCol, 'Team Win', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total(1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (multi-yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total', arbGroup)
            })
        })

        let playerRepGroupingData = {};
        arbData.forEach(arbCase => groupDataByCol('PlayerRep', playerRepGroupingData, arbCase));

        Object.values(playerRepGroupingData).forEach(arbGroup => {
            aggDataCols.forEach(aggDataCol => {
                arbGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', arbGroup)
                    + formatAggData(aggDataCol, 'Team Win', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total(1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (multi-yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total', arbGroup)
            })
        })

        let teamRepGroupingData = {};
        arbData.forEach(arbCase => groupDataByCol('TeamRep', teamRepGroupingData, arbCase));

        Object.values(teamRepGroupingData).forEach(arbGroup => {
            aggDataCols.forEach(aggDataCol => {
                arbGroup[aggDataCol] = formatAggData(aggDataCol, 'Player Win', arbGroup)
                    + formatAggData(aggDataCol, 'Team Win', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total(1yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Settled (multi-yr)', arbGroup)
                    + formatAggData(aggDataCol, 'Total', arbGroup)
            })
        })

        return {
            all: totalGroupingData,
            arbitrator: Object.values(arbGroupingData),
            season: Object.values(seasonGroupingData),
            playerPresenter: Object.values(playerPresenterGroupingData),
            playerRep: Object.values(playerRepGroupingData),
            teamRep: Object.values(teamRepGroupingData),
        };

        function formatAggData(colName, summaryType, arbGroupData) {
            if ((arbGroupData.SummaryInfo[summaryType].ArbCaseResult ?? 0) < 1)
                return '';

            let labelMapping = {
                'Player Win': 'Player: ',
                'Team Win': 'Team: ',
                'Settled (1yr)': 'Settled(1yr): ',
                'Settled (multi-yr)': 'Settled(multi-yr): ',
                'Total(1yr)': 'Total(1yr): ',
                Total: 'Total: ',
            }
            return (summaryType !== 'Player Win' ? '\n' : '')
                + (colName === 'ArbCaseResult' ? labelMapping[summaryType] : '')
                + arbGroupData.SummaryInfo[summaryType][colName];
        }

        function groupDataByCol(colName, mapObj, arbCase) {
            let colValue = arbCase[colName] ?? 'N/A';
            if (!Object.hasOwn(mapObj, colValue.toString()))
                mapObj[colValue.toString()] = {
                    SummaryInfo: {
                        'Player Win': {},
                        'Team Win': {},
                        'Settled (1yr)': {},
                        'Settled (multi-yr)': {},
                        'Total(1yr)': {},
                        Total: {},
                    },
                    '_children': [],
                }

            mapObj[colValue.toString()]['_children'].push(arbCase);
            mapObj[colValue.toString()][colName] = colValue

            mapObj[colValue.toString()].SummaryInfo[arbCase.ArbCaseResult] = dataAccumulator(
                mapObj[colValue.toString()].SummaryInfo[arbCase.ArbCaseResult],
                arbCase,
            );
            if (arbCase.ArbCaseResult !== 'Settled (multi-yr)') {
                mapObj[colValue.toString()].SummaryInfo['Total(1yr)'] = dataAccumulator(
                    mapObj[colValue.toString()].SummaryInfo['Total(1yr)'],
                    arbCase,
                );
            }
            mapObj[colValue.toString()].SummaryInfo.Total = dataAccumulator(
                mapObj[colValue.toString()].SummaryInfo.Total,
                arbCase,
            );

            mapObj[colValue.toString()] = Object.assign(
                mapObj[colValue.toString()],
                mapObj[colValue.toString()].SummaryInfo.Total,
            )
            function dataAccumulator(acc, cur) {
                let retData = {};
                retData.ArbCaseResult = (acc.ArbCaseResult ?? 0) + 1;
                retData.aWAR = (acc.aWAR ?? 0) + (cur.aWAR ?? 0);
                retData.ArbPlayerSubmission = (acc.ArbPlayerSubmission ?? 0) + cur.ArbPlayerSubmission;
                retData.ArbMidPoint = (acc.ArbMidPoint ?? 0) + cur.ArbMidPoint;
                retData.ArbTeamSubmission = (acc.ArbTeamSubmission ?? 0) + cur.ArbTeamSubmission;

                switch (cur.ArbCaseResult) {
                    case 'Player Win':
                        retData.ArbSpread = (acc.ArbSpread ?? 0) + cur.ArbSpread;
                        break;
                    case 'Team Win':
                        retData.ArbSpread = (acc.ArbSpread ?? 0) + -1 * cur.ArbSpread;
                        break;
                    case 'Settled (1yr)':
                        if ((cur.ArbMidPoint ?? 0) !== 0)
                            retData.ArbSpread = (acc.ArbSpread ?? 0) + (cur.ArbFinalAmount - cur.ArbMidPoint);
                        break;
                    default:
                        retData.ArbSpread = (acc.ArbSpread ?? 0);
                        break;
                }
                retData.ArbFinalAmount = (acc.ArbFinalAmount ?? 0) + cur.ArbFinalAmount;
                retData.YS = (acc.YS ?? 0) + (cur.YS ?? 0);
                retData.Raise = (acc.Raise ?? 0) + (cur.TimesElig !== 1 ? (cur.Raise ?? 0) : 0);
                cur.MoneyPeraWAR = cur.ArbFinalAmount / cur.aWAR;
                retData.MoneyPeraWAR = retData.ArbFinalAmount / retData.aWAR;

                return retData;
            }
        }
    }

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    function updateNotes(filters) {
        let notes = {
            formNotes: [],
        };
        notes.formNotes.push(`Filing Season From: ${startSeason} To: ${endSeason}`);

        if (filters) {
            filters.forEach(filter => {
                if (filter.value) {
                    let filterNote = convertFilterToNote(filter);
                    if (filterNote)
                        notes.formNotes.push(filterNote);
                }
            });
        }

        setExcelNotes(notes);
    }

    let seasonOptions = [];
    for (let i = curSeason; i >= 2003; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    };
    return (
        <div className='body arbitration-report pdfSection'>
            <div className='pdfChunk'>
                <h2>Arbitration Viewer</h2>
                <br />
                <Row>
                    <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>
                        Filing Season From: 
                    </Form.Label>
                    <Form.Control
                        as='select'
                        defaultValue={startSeason}
                        onChange={(event) => {
                            setStartSeason(event.target.value);
                        }}
                        style={{ width: '100px' }}
                    >
                        {seasonOptions}
                    </Form.Control>
                    <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>
                        Filing Season To:
                    </Form.Label>
                    <Form.Control
                        as='select'
                        defaultValue={endSeason}
                        onChange={(event) => {
                            setEndSeason(event.target.value);
                        }}
                        style={{ width: '100px' }}
                    >
                        {seasonOptions}
                    </Form.Control>
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            Historical Reports (Pre-2003)
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item target="_blank" href="/PDFs/Arbitration-FilingReport.pdf">Display 2002 and Prior Filing Report</Dropdown.Item>
                            <Dropdown.Item target="_blank" href="/PDFs/Arbirtation-ResultsByArbitrator.pdf">Display 2002 and Prior Results by Arbitrator</Dropdown.Item>
                            <Dropdown.Item target="_blank" href="/PDFs/Arbirtation-FillingSeason.pdf">Display 2002 and Prior Results by Filing Season</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Row>
                <br />
                <h5>Who's on this report</h5>
                <div className='pdfChunk advanced-filter'>
                    {
                        filters.map((filter, filterIndex) =>
                            <StatFilter
                                filter={filter}
                                key={filterIndex}
                                people={filter.people}
                                setFilter={setFilter}
                            />
                        )
                    }
                </div>
                <br />
                <Row className='pdfIgnore'>
                    <Button variant='success'
                        onClick={getData}
                    >
                        Submit
                    </Button>
                    <b className='label'>Summarize By: </b>
                    <Form>
                        <div key={'custom-inline-radio'}>
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='All'
                                type='radio'
                                id='custom-inline-radio-all'
                                defaultChecked
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('all')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='Arbitrators'
                                type='radio'
                                id='custom-inline-radio-arbitrator'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('arbitrator')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='Seasons'
                                type='radio'
                                id='custom-inline-radio-season'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('season')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='Player Presenter'
                                type='radio'
                                id='custom-inline-radio-playerPresenter'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('playerPresenter')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='Player Rep'
                                type='radio'
                                id='custom-inline-radio-playerRepresentative'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('playerRep')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='reportType'
                                label='Team Presenter'
                                type='radio'
                                id='custom-inline-radio-teamRepresentative'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setReportType('teamRep')
                                    }
                                }}
                            />
                        </div>
                    </Form>
                    {
                        data && columns && data[reportType] && columns[reportType] &&
                        <>
                            <div style={{ margin: '0px 0px 0px auto' }}>
                                <ExcelExporter
                                    columns={columns[reportType]}
                                    data={data[reportType].slice(0)}
                                    notes={excelNotes}
                                    reportName={'ArbitrationReport'}
                                />
                                <PDFExporter fileName='ArbitrationReport' />
                            </div>
                        </>
                    }
                </Row>
                <br />
            </div>
            {
                data?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                data && columns && data[reportType] && columns[reportType] &&
                <div
                    className='pdfChunk tableChunk'
                    style={{
                        width: (columns[reportType].reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                    }}
                >
                    <TabulatorTable
                        cols={columns[reportType]}
                        data={data[reportType]}
                        initSort={[{
                            column: columns[reportType][0].field,
                            dir: 'asc'
                        }]}
                        dataTreeElementColumn={columns[reportType][0].field}
                        dataTreeStartExpanded={reportType === 'all'}
                    />
                    <br />
                </div>
            }
            {
                data?.exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data?.exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
        </div>
    )
}

export default ArbitrationReport;