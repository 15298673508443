import $JExcel from '../../Libraries/myexcel.js'
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function setExcelCell(sheet, sheetId, colId, rowId, data, col, isTotalRow, isLastRow, styleOverride, tableType) {
    let style = {};

    if (col.cssClass && col.cssClass.includes('break') && isTotalRow) {
        style.border = 'none, thin #333333, thin #333333, medium #333333';
    }
    else if (col.cssClass && col.cssClass.includes('break') && isLastRow) {
        style.border = 'none, thin #333333, none, medium #333333';
    }
    else if (col.cssClass && col.cssClass.includes('break')) {
        style.border = 'none, thin #333333, none, none';
    }
    else if (isTotalRow) {
        style.border = 'none, none, thin #333333, medium #333333';
    }
    else if (isLastRow) {
        style.border = 'none, none, none, medium #333333';
    }

    style.format = col.exportFormat

    if (data || data === 0) {
        if (col.exportFormat && col.exportFormat === 'm/d/yyyy') {
            data = $JExcel.toExcelLocalTime(new Date(data));
        }
        if (col.withPercent && tableType !== 'RankReport') {
            data = data / 100;
        }
        if (col.field !== 'Awards' && col.field !== 'YSNext' && col.field !== 'AAVNext') {
            style.align = 'C C';
        }
        if (styleOverride) {
            style.border = styleOverride.border ? styleOverride.border : style.border;
            style.format = styleOverride.format ? styleOverride.format : style.format;
            style.align = styleOverride.align ? styleOverride.align : style.align;
            style.fill = styleOverride.fill ? styleOverride.fill : style.fill;
        }
        sheet.set(sheetId, colId, rowId, data, sheet.addStyle(style));
    }
    else {
        sheet.set(sheetId, colId, rowId, '', sheet.addStyle(style));
    }
}

function setHeaders(columns, excel, sheetId, rowId) {
    function replaceSupSub(text) {
        return text.replaceAll('<sup>', '(')
            .replaceAll('<sub>', '(')
            .replaceAll('</sup>', ')')
            .replaceAll('</sub>', ')')
    }

    let hasNestedHeaders = false;
    columns.forEach(col => {
        if (col.columns && col.columns.length > 0) {
            hasNestedHeaders = true;
        }
    })

    if (hasNestedHeaders) {
        rowId += 1;
    }
    let subCols = 0;
    for (var i = 0; i < columns.length; i++) {
        if (columns[i].columns && columns[i].columns.length > 0) {
            excel.set(
                sheetId,
                i + subCols,
                rowId - 1,
                columns[i].title,
                excel.addStyle({
                    border: 'none, none, medium #333333, none',
                    align: 'C C'
                }),
                columns[i].columns.length
            );
            for (var j = 0; j < columns[i].columns.length; j++) {
                if (columns[i].columns[j].cssClass && columns[i].columns[j].cssClass.includes('break')) {
                    if (hasNestedHeaders) {
                        excel.set(
                            sheetId,
                            i + j + subCols,
                            rowId - 1,
                            '',
                            excel.addStyle({
                                border: 'none, thin #333333, medium #333333, none',
                                align: 'C C'
                            })
                        );
                    }
                    excel.set(
                        sheetId,
                        i + j + subCols,
                        rowId,
                        replaceSupSub(columns[i].columns[j].title),
                        excel.addStyle({
                            border: 'none, thin #333333, none, thin #333333',
                            align: 'C C'
                        })
                    );
                }
                else {
                    excel.set(
                        sheetId,
                        i + j + subCols,
                        rowId,
                        replaceSupSub(columns[i].columns[j].title),
                        excel.addStyle({ align: 'C C' })
                    );
                }
            }
            subCols += columns[i].columns.length - 1;
        }
        else {
            if (columns[i].cssClass && columns[i].cssClass.includes('break')) {
                if (hasNestedHeaders) {
                    excel.set(
                        sheetId,
                        i + subCols,
                        rowId - 1,
                        '',
                        excel.addStyle({
                            border: 'none, thin #333333, medium #333333, none',
                            align: 'C C'
                        })
                    );
                    excel.set(
                        sheetId,
                        i + subCols,
                        rowId,
                        replaceSupSub(columns[i].title),
                        excel.addStyle({
                            border: 'none, thin #333333, none, thin #333333',
                            align: 'C C'
                        })
                    );
                }
                else {
                    excel.set(
                        sheetId,
                        i + subCols,
                        rowId,
                        replaceSupSub(columns[i].title),
                        excel.addStyle({
                            border: 'none, thin #333333, medium #333333, thin #333333',
                            align: 'C C'
                        })
                    );
                }
            }
            else {
                excel.set(
                    sheetId,
                    i + subCols,
                    rowId,
                    replaceSupSub(columns[i].title),
                    excel.addStyle({ align: 'C C' })
                );
            }
        }
    }

    if (hasNestedHeaders) {
        excel.set({
            sheet: sheetId,
            row: rowId - 1,
            style: excel.addStyle({
                fill: '#A9A9A9',
                border: 'none, none, medium #333333, none'
            })
        });
        excel.set({
            sheet: sheetId,
            row: rowId,
            style: excel.addStyle({
                fill: '#A9A9A9',
                border: 'none, none, none, thin #333333'
            })
        });
    }
    else {
        excel.set({
            sheet: sheetId,
            row: rowId,
            style: excel.addStyle({
                fill: '#A9A9A9',
                border: 'none, none, medium #333333, thin #333333'
            })
        });
    }

    return rowId + 1;
}

function setTableData(excel, sheetId, rowId, table, columns, tableType, parentIsLastRow) {
    parentIsLastRow = parentIsLastRow === undefined ? true : parentIsLastRow;
    for (let i = 0; i < table.length; i++) {
        if ((table[i].TotalCategory && table[i].TotalCategory.includes('scaleUp')) || table[i].scaleUp) {
            excel.set({
                sheet: sheetId,
                row: rowId,
                style: excel.addStyle({
                    fill: '#FED8B1'
                })
            });
        }
        let hasChildRows = (table[i]['_children'] && table[i]['_children'].length > 0) === true;
        let isLastRow = parentIsLastRow && i === table.length - 1;
        let subCols = 0;
        for (let j = 0; j < columns.length; j++) {
            if (columns[j].columns && columns[j].columns.length > 0) {
                for (let k = 0; k < columns[j].columns.length; k++) {
                    setExcelCell(
                        excel,
                        sheetId,
                        j + k + subCols,
                        rowId,
                        table[i][columns[j].columns[k].field],
                        columns[j].columns[k],
                        (!hasChildRows || table[i].Player === 'Career Totals') && isLastRow && tableType === 'player',
                        !hasChildRows && isLastRow && tableType !== 'player',
                        tableType === 'RankReport' ? { format: '0' } : null,
                        tableType
                    );
                }
                subCols += columns[j].columns.length - 1;
            }
            else {
                setExcelCell(
                    excel,
                    sheetId,
                    j + subCols,
                    rowId,
                    table[i][columns[j].field],
                    columns[j],
                    (!hasChildRows || table[i].Player === 'Career Totals') && isLastRow && tableType === 'player',
                    !hasChildRows && isLastRow && tableType !== 'player',
                    tableType === 'RankReport' ? { format: '0' } : null,
                    tableType
                );
            }
        }
        rowId += 1;

        // If the row has nested rows(data from separate teams)
        if (hasChildRows) {
            rowId = setTableData(excel, sheetId, rowId, table[i]['_children'], columns, tableType, isLastRow);
        }
    }
    
    return rowId;
}

function setTableNotes(excel, sheetId, rowId, notes) {
    rowId = setNotes(excel, sheetId, rowId, notes.timePeriodNotes, 'Time Period');
    rowId = setNotes(excel, sheetId, rowId, notes.playerSeasonNotes, 'Player Season');
    rowId = setNotes(excel, sheetId, rowId, notes.playerCriteriaNotes, 'Player Criteria');
    rowId = setNotes(excel, sheetId, rowId, notes.numericalCriteriaNotes, 'Numerical Criteria');
    let throughXNotes = [];
    if (notes.throughXStatNote) throughXNotes.push(notes.throughXStatNote);
    if (notes.consecutiveYearsNote) throughXNotes.push(notes.consecutiveYearsNote);
    rowId = setNotes(excel, sheetId, rowId, throughXNotes, 'Through');
    rowId = setNotes(excel, sheetId, rowId, notes.displayStatNotes);
    if (notes.scaleUpNotes)
        rowId = setNotes(excel, sheetId, rowId, [notes.scaleUpNotes], 'Scale Up', { fill: '#FED8B1' });
    rowId = setNotes(excel, sheetId, rowId, notes.formNotes, 'Filter');
    rowId = setNotes(excel, sheetId, rowId, notes.situationNotes, 'Situation');

    return ++rowId;
}

function setNotes(excel, sheetId, rowId, notes, noteLabel, styleOverride) {
    notes?.map((note, index) => {
        setExcelCell(
            excel,
            sheetId,
            0,
            rowId,
            noteLabel ?? index + 1 + '',
            { field: 'noteIndex' },
            null,
            null,
            styleOverride,
            null
        );
        setExcelCell(
            excel,
            sheetId,
            1,
            rowId,
            note,
            { field: 'note' },
            null,
            null,
            styleOverride,
            null
        );
        rowId++;
    })

    return rowId;
}

function sortFunc(a, b) {
    if (!isNaN(a.Season)) {
        return a.Season - b.Season;
    }
    return a.Player === 'Career Totals' ? 1 : -1;
};

function setColumnWidths(excel, sheetId, columns) {
    columns.forEach((column, index) => {
        let columnWidth = (column.width / 4);
        excel.set({ sheet: sheetId, column: index, value: columnWidth });
    })
}

function exportCompReportSheet(props) {
    let excel = $JExcel.new('Arial dark 10 #333333');
    let sheetId = 0;
    let rowId = 0;
    excel.set({ sheet: sheetId, value: 'Sheet 1' });
    setColumnWidths(excel, sheetId, props.columns);


    props.players.forEach(player => {
        if (player && player.length > 0) {
            rowId = setHeaders(props.columns, excel, sheetId, rowId);
            rowId = setTableData(excel, sheetId, rowId, player.sort(sortFunc), props.columns, 'player');
            rowId += 2;
        }
    })

    if (props.platformYearData) {
        excel.set(sheetId, 0, rowId, 'Platform', excel.addStyle({ fill: '#A9A9A9', border: 'medium #333333, medium #333333, medium #333333, none' }));
        rowId += 1;
        rowId = setHeaders(props.columns, excel, sheetId, rowId);
        rowId = setTableData(excel, sheetId, rowId, props.platformYearData, props.columns, 'platform');
        rowId += 1;
    }
    if (props.platformYearMinusOneData) {
        excel.set(sheetId, 0, rowId, 'Platform - 1', excel.addStyle({ fill: '#A9A9A9', border: 'medium #333333, medium #333333, medium #333333, none' }));
        rowId += 1;
        rowId = setHeaders(props.columns, excel, sheetId, rowId);
        rowId = setTableData(excel, sheetId, rowId, props.platformYearMinusOneData, props.columns, 'platform');
        rowId += 1;
    }
    if (props.platformYearMinusTwoData) {
        excel.set(sheetId, 0, rowId, 'Platform - 2', excel.addStyle({ fill: '#A9A9A9', border: 'medium #333333, medium #333333, medium #333333, none' }));
        rowId += 1;
        rowId = setHeaders(props.columns, excel, sheetId, rowId);
        rowId = setTableData(excel, sheetId, rowId, props.platformYearMinusTwoData, props.columns, 'platform');
    }

    rowId += 2;

    if (props.lastTwoYearsData) {
        excel.set(sheetId, 0, rowId, 'Last Two Years', excel.addStyle({ fill: '#A9A9A9', border: 'medium #333333, medium #333333, medium #333333, none' }));
        rowId += 1;
        rowId = setHeaders(props.columns, excel, sheetId, rowId);
        rowId = setTableData(excel, sheetId, rowId, props.lastTwoYearsData, props.columns, 'aggregate');
        rowId += 1;
    }
    if (props.lastThreeYearsData) {
        excel.set(sheetId, 0, rowId, 'Last Three Years', excel.addStyle({ fill: '#A9A9A9', border: 'medium #333333, medium #333333, medium #333333, none' }));
        rowId += 1;
        rowId = setHeaders(props.columns, excel, sheetId, rowId);
        rowId = setTableData(excel, sheetId, rowId, props.lastThreeYearsData, props.columns, 'aggregate');
        rowId += 1;
    }
    if (props.careerToDateData) {
        excel.set(sheetId, 0, rowId, 'Career through Platform Year', excel.addStyle({ fill: '#A9A9A9', border: 'medium #333333, medium #333333, medium #333333, none' }));
        rowId += 1;
        rowId = setHeaders(props.columns, excel, sheetId, rowId);
        rowId = setTableData(excel, sheetId, rowId, props.careerToDateData, props.columns, 'aggregate');
    }

    excel.generate('CompReport');
}

function exportLeaderboardsSheet(props) {
    let excel = $JExcel.new('Arial dark 10 #333333');
    let sheetId = 0;
    let rowId = 0;
    excel.set({ sheet: sheetId, value: 'Sheet 1' });
    setColumnWidths(excel, sheetId, props.columns);


    if (props.notes) {
        rowId = setTableNotes(excel, sheetId, rowId, props.notes);
    }

    if (props.data && props.data.length > 0) {
        rowId = setHeaders(props.columns, excel, sheetId, rowId);

        rowId = setTableData(excel, sheetId, rowId, props.data, props.columns, props.reportName) + 2;
    }

    excel.generate(props.reportName);
}

function exportPlayerAwardsSheet(props) {
    let excel = $JExcel.new('Arial dark 10 #333333');
    let sheetId = 0;
    let rowId = 0;
    excel.set({ sheet: sheetId, value: 'Sheet 1' });
    //setColumnWidths(excel, props);

    props.tables.forEach(table => {
        if (table && table.data?.length > 0 && table.columns?.length > 0) {
            if (table.tableHeader) {
                excel.set(
                    sheetId,
                    0,
                    rowId,
                    table.tableHeader,
                    excel.addStyle({ fill: '#A9A9A9', border: 'medium #333333, medium #333333, medium #333333, none' })
                );
                rowId += 1;
            }
            
            rowId = setHeaders(table.columns, excel, sheetId, rowId);
            rowId = setTableData(
                excel,
                sheetId,
                rowId,
                table.sortFunc ? table.data.sort(table.sortFunc) : table.data,
                table.columns,
                'PlayerAwards'
            );
            rowId += 2;
        }
    })

    excel.generate(props.reportName);
}

function exportRankReportSheet(props) {
    let excel = $JExcel.new('Arial dark 10 #333333');
    let sheetId = 0;
    let rowId = 0;
    excel.set({ sheet: sheetId, value: 'Sheet 1' });
    setColumnWidths(excel, sheetId, props.columns);

    props.players.forEach(player => {
        if (player.data && player.data.length > 0) {
            excel.set(sheetId, 0, rowId, player.data[0].Player, excel.addStyle({ fill: '#A9A9A9', border: 'medium #333333, medium #333333, medium #333333, none' }));
            rowId += 1;
            rowId = setHeaders(props.columns, excel, sheetId, rowId);

            rowId = setTableData(excel, sheetId, rowId, player.data, props.columns, props.reportName) + 2;
        }
    })

    excel.generate('RankReport');
}

function exportVariantSheet(props) {
    let excel = $JExcel.new('Arial dark 10 #333333');

    props.sheetNames.forEach((sheetName, sheetIndex) => {
        if (sheetIndex > 0)
            excel.addSheet();

        let rowId = 0;
        excel.set({ sheet: sheetIndex, value: sheetName });
        setColumnWidths(excel, sheetIndex, props.columns[sheetName]);

        if (props.notes[sheetName]) {
            rowId = setTableNotes(excel, sheetIndex, rowId, props.notes[sheetName]);
        }

        if (props.data && props.data[sheetName]?.length > 0) {
            rowId = setHeaders(props.columns[sheetName], excel, sheetIndex, rowId);

            rowId = setTableData(
                excel,
                sheetIndex,
                rowId,
                props.data[sheetName],
                props.columns[sheetName],
                props.reportName
            ) + 2;
        }
    })

    excel.generate(props.reportName);
}

function ExcelExporter(props) {
    const [childRows, setChildRows] = useState('Both');
    let propsObj = Object.assign({}, props);

    function exportSheet(props) {
        // Don't display row number in excel
        propsObj.columns = propsObj.columns?.length > 0
            ? propsObj.columns?.filter(col => col.title !== '#')
            : propsObj.columns;
        propsObj.data = filterChildRows(propsObj.data);
        switch (propsObj.reportName) {
            case 'CompReport':
                exportCompReportSheet(propsObj);
                break;
            case 'ArbitrationReport':
            case 'Awards':
            case 'Injuries':
            case 'LargestContracts':
            case 'Leaderboards':
            case 'QueryTool':
            case 'RecentSignings':
            case 'RosterSummary':
            case 'TransactionsReport':
            case 'Transactions':
                exportLeaderboardsSheet(propsObj);
                break;
            case 'PlayerAwards':
                exportPlayerAwardsSheet(propsObj);
                break;
            case 'RankReport':
                exportRankReportSheet(propsObj);
                break;
            case 'ReliefPitcher':
            case 'PlayerContract':
            case 'PlayerStats':
                exportVariantSheet(propsObj);
                break;
            default:

                break;
        }
    }

    function filterChildRows(data) {
        if (propsObj.reportName === 'LargestContracts') {
            return data.flatMap((value) => {
                let totalObj = Object.assign({}, value);
                delete totalObj._children;
                return totalObj;
            });
        }
        else if (props.reportName === 'ArbitrationReport') {
            const summaryGroups = ['Player Win', 'Team Win', 'Settled (1yr)', 'Total(1yr)', 'Setled (multi-yr)', 'Total'];
            return data.slice(0).flatMap((row) => {
                if (Object.hasOwn(row, '_children')) {
                    let childRows = row._children.slice(0);
                    delete row._children;
                    return summaryGroups
                        .map(summaryGroup => {
                            if (row.SummaryInfo[summaryGroup] == null || Object.keys(row.SummaryInfo[summaryGroup]).length === 0)
                                return null;

                            let summaryRow = Object.assign(Object.assign({}, row), row.SummaryInfo[summaryGroup]);
                            summaryRow.ArbCaseResult = `${summaryGroup}: ${summaryRow.ArbCaseResult}`;
                            return summaryRow;
                        })
                        .filter(row => row !== null)
                        .concat(childRows.map(childRow => {
                            childRow.ArbitratorCol = childRow?.ArbitratorCol?.replaceAll('\n', '');
                            if (childRow.MoneyPeraWAR === Infinity)
                                childRow.MoneyPeraWAR = null;
                            return childRow;
                        }));
                }
                return row;
            })
        }
        else {
            switch (childRows) {
                case 'Both':
                    return data;
                case 'Child':
                    return data.flatMap((value) => {
                        if (value._children == null) {
                            return value;
                        }
                        else {
                            return value._children;
                        }
                    });
                case 'Total':
                    return data.flatMap((value) => {
                        let totalObj = Object.assign({}, value);
                        delete totalObj._children;
                        return totalObj;
                    });
                default:
                    return data;
            }
        }
    }

    return (
        <React.Fragment>
            <img
                width='90'
                height='100'
                src={'./images/ExcelButton.png'}
                alt=''
                type='button'
                onClick={() => exportSheet(props)} style={props.style}
            />
            {propsObj.reportName == 'Leaderboards' &&
                <div className='row'>
                    <b className='label'>Excel Export Format: </b>
                    <div key={'custom-inline-radio'}>
                        <Form.Check
                            custom
                            inline
                            name='childRowsFormat'
                            label='Both Detail and Total Records'
                            type='radio'
                            id='custom-inline-radio-both-rows'
                            defaultChecked
                            onChange={() => setChildRows('Both')}
                        />
                        <Form.Check
                            custom
                            inline
                            name='childRowsFormat'
                            label='Detail Records Only'
                            type='radio'
                            id='custom-inline-radio-child-rows'
                            onChange={() => setChildRows('Child')}
                        />
                        <Form.Check
                            custom
                            inline
                            name='childRowsFormat'
                            label='Total Records Only'
                            type='radio'
                            id='custom-inline-radio-total-rows'
                            onChange={() => setChildRows('Total')}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    )

}

export default ExcelExporter;
